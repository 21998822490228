var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"navigation-search"},[_c('section',{ref:"teamfinder",staticClass:"search"},[_c('div',{staticClass:"search__mask",class:{ 'search__mask--expand': (_vm.modalIsActive || _vm.searchHasFocus) && false },on:{"click":_vm.onSearchMaskClick}}),_c('div',{staticClass:"search__background"}),_c('div',{staticClass:"search__action-bar",class:{ 'search__action-bar--expand': _vm.searchHasFocus && _vm.expand }},[_c('button',{staticClass:"search__action-bar-close",class:{ 'search__action-bar-close--expand': _vm.searchHasFocus && _vm.expand },attrs:{"type":"button","aria-label":"clear search"},on:{"click":_vm.clearSearch}},[_c('IconArrow')],1)]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"search__search-row",class:{ 'search__search-row--finder-open': _vm.showFinder }},[_c('div',{staticClass:"search__search-wrapper"},[_c('LargeSearchBar',{attrs:{"searchValue":_vm.searchStr,"placeHolder":_vm.model.Placeholder,"getHintsOnInput":true,"hasFocus":_vm.searchHasFocus === null},on:{"focus":function (evt) {
                _vm.handleSearchFocus(true, evt);
              },"blur":function (evt) {
                _vm.handleSearchFocus(false, evt);
              },"searchdata":_vm.handleSearchInput}}),(!_vm.expand && _vm.model.SearchCta)?_c('div',{staticClass:"text-right"},[_c('a',{staticClass:"cta cta--white",attrs:{"href":_vm.model.SearchCta.Link}},[_vm._v(_vm._s(_vm.model.SearchCta.Title))])]):_vm._e(),_c('div',{staticClass:"search__search-context custom-scroll",class:{
              'search__search-context--expand': _vm.searchHasFocus && _vm.expand,
              'search__search-context--expand-results': _vm.searchResults.length > 0 && _vm.expand,
            }},[_c('div',{ref:"results",staticClass:"search__search-results",class:{
                'search__search-results--expand-results': _vm.searchResults.length > 0 && _vm.expand,
              }},[_c('transition-group',{staticClass:"search__search-results-list",attrs:{"tag":"div","name":"search-results-fade","appear":""}},_vm._l((_vm.searchResults),function(item,index){return _c('div',{key:item.Link,staticClass:"search__search-results-item text--small",style:({ '--i': index }),on:{"click":function($event){return _vm.productClicked({ url: item.Link, position: index + 1 })}}},[_c('SearchPreviewCard',{attrs:{"p":item}})],1)}),0)],1),(_vm.expand)?_c('SearchSuggestions',{staticClass:"search__search-suggestions",attrs:{"p":_vm.model.SearchSuggestions,"SearchCta":_vm.model.SearchCta},on:{"selected":_vm.handleSuggestionSelected}}):_vm._e()],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }