import {AnnouncementRibbonViewModel} from "@/components/AnnouncementRibbon/AnnouncementRibbon.types";
import { SegmentElement } from "@/plugins/utm/SegmentElement";
import {getComponentProps} from "@/utils/vueUtils";
import {Component, Vue} from 'vue-property-decorator';

const AnnouncementRibbonProps = Vue.extend({
  props: getComponentProps<AnnouncementRibbonViewModel>({
    Announcements: ["Hello!"]
  }),
});

@Component({
  components: {},
  }
)
export default class AnnouncementRibbon extends AnnouncementRibbonProps{
  onGlobalNavigationClicked(e) {
    if (e.target.tagName === 'A') {
      const params = SegmentElement.getButtonParams(e.target);
      SegmentElement.sendEvent('globalNavigationClicked', {...params, navigation_level: 'announcement'});
    }
  }
}
