import { Component, Vue } from 'vue-property-decorator';
import { NavigationListViewModel } from './NavigationList.types';
import LinkItem from '@/common/interfaces/LinkItem';
import orderBy from 'lodash/orderBy';

const NavigationListProps = Vue.extend({
  props: {
    p: Object as () => NavigationListViewModel,
    active: Boolean as () => Boolean,
    mobileLinks: Array as () => Array<LinkItem>,
  },
});

@Component({})
export default class NavigationList extends NavigationListProps {
  get filteredMobileLinks() {
    if (this.mobileLinks) {
      return this.mobileLinks.filter((link) => {
        if (link.Link && link.Title) return link;
      });
    }
  }

  get sortedNavigation() {
    if (this.p.Title && this.p.Title.toLowerCase().indexOf('destination') > 0) {
      return orderBy(this.p.Links, 'Title');
    }
    return this.p.Links;
  }
}
