import { Component, Vue, Prop } from 'vue-property-decorator';
import Email from '@/assets/icons/email.svg?inline';
import Book from '@/assets/icons/book.svg?inline';
import Globe from '@/assets/icons/globe.svg?inline';
import Question from '@/assets/icons/question-mark.svg?inline';
import Phone from '@/assets/icons/phone.svg?inline';
import { NavigationTextViewModel } from './NavigationText.types';
import CtaButton from './../CtaButton/CtaButton.vue';

const NavigationTextProps = Vue.extend({
  props: { p: Object as () => NavigationTextViewModel },
});

@Component({
  components: {
    CtaButton,
    Email,
    Book,
    Globe,
    Question,
    Phone,
  },
})
export default class NavigationText extends NavigationTextProps {
  get containerElement(): string {
    return this.p.Link && this.p.Link.Link ? 'a' : 'div';
  }

  get containerAttrs(): object {
    return this.p.Link && this.p.Link.Link && !this.p.Cta
      ? {
          href: this.p.Link.Link,
        }
      : {};
  }

  get Cta() {
    const btn = this.p.Cta.Link
    return {
      ...this.p.Cta,
      Link: btn && btn.startsWith('+1300') ? btn.replace(/\+/g, '') : btn
    }
  }
}
