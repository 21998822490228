import { Component, Vue, Prop } from 'vue-property-decorator';
import { NavigationCardViewModel } from './NavigationCard.types';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import { ImageSizes, ImageSize } from '@/common/interfaces/SizeBreakpoints';

const NavigationCardProps = Vue.extend({
  props: { p: Object as () => NavigationCardViewModel },
});

@Component({
  components: {
    ResponsiveImage,
  },
})
export default class NavigationCard extends NavigationCardProps {
  imageSizes: ImageSizes = {
    def: new ImageSize([560, 667]),
    sizes: {
      sm: new ImageSize([767, 432]),
      md: new ImageSize([991, 432]),
    },
  };

  get containerElement(): string {
    return this.p.Link.Link ? 'a' : 'div';
  }

  get containerAttrs(): object {
    return this.p.Link.Link
      ? {
          href: this.p.Link.Link,
        }
      : {};
  }

  get isFeatured(): boolean {
    return this.p.Size === 2;
  }
}
