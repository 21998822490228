import { Component, Prop } from 'vue-property-decorator';
import { SwiperOptions } from '@/utils/swiperUtils';

import { NavigationSliderViewModel } from './NavigationSlider.types';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import { ImageSizes, ImageSize } from '@/common/interfaces/SizeBreakpoints';
import { LG } from '@/utils/breakpointUtils';
import { mixins } from 'vue-class-component';
import SwiperMixin from '@/mixins/SwiperMixin';
import { BREAKPOINTS } from '@/common/interfaces/SizeBreakpoints';

@Component({
  components: {
    ResponsiveImage,
  },
})
export default class NavigationSlider extends mixins(SwiperMixin) {
  @Prop()
  readonly p!: NavigationSliderViewModel;

  swiperActiveIndex: number = 0;
  windowWidth: number = 0;

  imageSizes: ImageSizes = {
    def: new ImageSize([560, 667]),
    sizes: {
      sm: new ImageSize([767, 432]),
      md: new ImageSize([991, 432]),
      lg: new ImageSize([1199, 618]),
      xl: new ImageSize([1500, 618]),
    },
  };

  get getBreakpointXl() {
    return BREAKPOINTS.xl;
  }

  slideNext() {
    this.swiper.slideNext()
  }

  slidePrev() {
    this.swiper.slidePrev()
  }

  slideTo(index: number) {
    this.swiper.slideTo(index);
    this.swiper.on('slideChangeTransitionStart', () => {
      this.swiperActiveIndex = this.swiper.activeIndex;
    });
  }

  get paginationArray() {
    if (this.p.Slides) {
      return this.p.Slides;
    }
  }

  get swiperOption(): SwiperOptions {
    return {
      slidesPerView: 1.4,
      spaceBetween: 25,
      centeredSlides: false,
      speed: 500,
      autoplay: false,
      pagination: {
        el: `#c-${this.uuid}-swiper-pagination`,
        clickable: true,
      },
      breakpoints: {
        1200: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    };
  }

  mounted() {
    // this.autoplay();
    // window.addEventListener('resize', this.autoplay);

    window.addEventListener('resize', this.handleResizeEvent);
    this.handleResizeEvent();
  }

  updated() {
    this.swiper.on('slideChangeTransitionStart', () => this.swiperActiveIndex = this.swiper.activeIndex);
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResizeEvent);
  }

  handleResizeEvent() {
    let newWidth = document.documentElement.clientWidth;
    this.windowWidth = newWidth;
  }

  autoplay() {
    if (this.swiper && this.swiper.autoplay) {
      if (window.innerWidth < LG) {
        this.swiper.autoplay.start();
      } else {
        this.swiper.autoplay.stop();
      }
    }
  }
}
